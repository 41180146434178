<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="650">
      <template v-slot:activator="{ on, attrs }">
        <v-btn  v-bind="attrs" v-on="on"> Share Report </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Share with People </v-card-title>

        <v-card-text>
          <v-form class="form-shared-link">
            <v-row>
              <v-col class="col-12">
                <div class="line-options">
                  <v-switch
                    v-model="shared_enabled"
                    :label="getSwitchText()"
                    hide-details
                    @click="setEnabled"
                  ></v-switch>
                  <v-btn small text v-if="shared_enabled" @click="newLink" :disabled="loading_link">
                    <template v-if="!loading_link">Generate a new link</template>
                    <v-progress-circular v-else indeterminate></v-progress-circular>
                  </v-btn>
                </div>
              </v-col>
              <v-col class="col-12" v-if="shared_enabled">
                <div class="line-row">
                  <v-text-field
                    ref="shared_link"
                    v-model="shared_link"
                    outlined
                    label="Shared Link"
                    placeholder="Shared Link"
                    hint="Anyone with this url can view the report"
                    persistent-hint
                    dense
                  ></v-text-field>
                  <v-tooltip
                    v-model="tooltip"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                      outlined
                      small
                      class="copied"
                      @click="copyToClipboard"
                      v-bind="attrs"
                      >
                        <v-icon>mdi-content-copy</v-icon>
                      </v-btn>
                    </template>

                    <span>Copied</span>
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>
          </v-form>

          <v-divider></v-divider>

          <v-form class="form-shared-email" ref="formEmail">
            <v-row>
              <v-col class="col-12">
                <div class="line-options">
                  <span>Invite People</span>
                  <v-btn small text @click="showDailogEmail">Shared withs <v-icon style="margin-left: 5px">mdi-eye</v-icon></v-btn>
                </div>
              </v-col>
              <v-col class="col-12">
                <div class="line-row">
                  <v-text-field
                    v-model="email"
                    outlined
                    type="email"
                    label="Email"
                    placeholder="Email"
                    hide-details
                    dense
                    :rules="[validator.required, validator.emailValidator]"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col class="col-12">
                <div class="line-row">
                  <v-text-field
                    v-model="message"
                    outlined
                    label="Message"
                    placeholder="Message"
                    hide-details
                    dense
                    textarea
                    rows="2"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col class="col-12">
                <v-btn outlined color="primary" @click="shareEmail"  :disabled="loading_email">
                <template v-if="!loading_email">Send Email</template>
                <span v-else>
                  <v-progress-circular indeterminate style="margin-right: 5px"></v-progress-circular>
                  <span>Sending...</span>
                </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" dense text @click="dialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <dialog-shared-email
    :report="report"
    :report_type="report_type"
    :dialog.sync="dialogEmail"
    :backDialog="backDialog"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { required, urlValidator, emailValidator } from '@core/utils/validation'
import DialogSharedEmail from './DialogSharedEmail.vue';

export default {
  components: {
    DialogSharedEmail
  },
  props: {
    report: Object,
    report_type: String
  },
  data() {
    return {
      dialog: false,
      dialogEmail: false,
      loading_link: false,
      loading_email: false,
      shared_link: '',
      shared_enabled: false,
      email: '',
      message: '',
      token: '',
      tooltip: false,
      validator: {
        required,
        urlValidator,
        emailValidator,
      }
    }
  },
  computed: {
    ...mapGetters({
      hasUserRole: 'auth/hasUserRole',
      hasUserPermission: 'auth/hasUserPermission',
    }),
  },
  mounted() {
    this.getLink()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'snackbar/set',
      generateLink: 'share/generateLink',
      generateNewLink: 'share/generateNewLink',
      shareAndSendEmail: 'share/shareAndSendEmail',
    }),
    getSwitchText() {
      return `Shared link is ${this.shared_enabled ? 'enabled' : 'disabled'}`
    },
    backDialog() {
      this.dialogEmail = false;
      this.dialog = true;
    },
    showDailogEmail() {
      this.dialogEmail = true;
      this.dialog = false;
    },
    async copyToClipboard() {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(this.shared_link)
      } else {
        this.$refs.shared_link.focus()
        document.execCommand('copy')
      }
      this.copyTooltip()
    },
    async copyTooltip() {
      this.tooltip = true;
      setTimeout(() => {
        this.tooltip = false;
      }, 5000);
    },
    async setEnabled() {
      const form_data = {
        report_id: this.report.id,
        report_type: this.report_type,
        enabled: this.shared_enabled,
      }
      this.getReportLink(form_data)
    },
    async getLink() {
      const form_data = {
        report_id: this.report.id,
        report_type: this.report_type,
      }
      this.getReportLink(form_data)
    },
    async getReportLink(form_data) {
      this.loading_link = true
      await this.generateLink(new URLSearchParams(form_data).toString())
        .then(resp => {
          if (resp && resp.data) {
            this.shared_link = resp.data.shared_link
            this.token = resp.data.token
            if (form_data.enabled == null) {
              this.shared_enabled = resp.data.enabled
            }
          }
        })
        .finally(() => {
          this.loading_link = false
        })
    },
    async newLink() {
      const form_data = {
        report_id: this.report.id,
        report_type: this.report_type,
        token: this.token,
      }
      this.loading_link = true
      await this.generateNewLink(new URLSearchParams(form_data).toString())
        .then(resp => {
          if (resp && resp.data) {
            this.shared_link = resp.data.shared_link
            this.token = resp.data.token
            this.shared_enabled = resp.data.enabled
          }
        })
        .finally(() => {
          this.loading_link = false
        })
    },
    async shareEmail() {
      if (this.$refs.formEmail.validate()) {
        const form_data = {
          report_id: this.report.id,
          report_type: this.report_type.trim(),
          email: this.email.trim(),
          message: this.message ?  this.message.trim() : null,
        }
        this.loading_email = true
        await this.shareAndSendEmail(new URLSearchParams(form_data).toString())
          .then(resp => {
            if (resp && resp.data) {

              this.setSnackbar({
                status: true,
                text: 'Report successfully shared.',
                color: 'primary',
              });
              this.$refs.formEmail.reset();
            }
          })
          .catch(error => {
            this.setSnackbar({
              status: true,
              text: 'Failed to share report url.',
              color: 'error',
            })
          })
          .finally(() => {
            this.loading_email = false
          })
      }
    },
  },
}
</script>

<style lang="css">
.form-shared-link {
  padding-bottom: 40px;
}
.form-shared-email {
  padding: 20px 0px;
}
.line-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
}
.line-options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.link-details {
  margin-left: 5px;
}
.copied {
  min-height: 40px;
}
</style>
