<template>
  <div class="text-center">
    <v-dialog width="650" v-model="dialog" @click:outside="closeDialog">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2 header">
          <span>Shared withs</span>
          <v-btn
            v-if="items && items.length > 0"
            color="error"
            text @click="dialog_delete_all = true">
            Remove All
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-data-table
            :loading="loading"
            :headers="headers"
            :items="items"
            sort-by="id"
            :sort-desc="sort_desc"
            :footer-props="{ 'items-per-page-options': items_per_page }"
            :options.sync="options"
            mobile-breakpoint="0"
          >
          <template v-slot:[`item.created_at`]="{ item }">
            <a class="pr-2 text--darken-3 text--secondary" @click="goToAction(item)">
              {{ formatDate(item.created_at) }}
            </a>
          </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div class="cont-actions">
                <v-icon color="error" @click="showDeleteDialog(item)"> mdi-delete </v-icon>
              </div>
            </template>
          </v-data-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="backDialog"> Back </v-btn>
          <v-btn color="primary" text @click="closeDialog"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row justify="center">
      <v-col cols="12" md="6">
        <dialog-delete-report
          :show.sync="dialog_delete.show"
          :item="dialog_delete.item"
          title="Are you sure to delete this email?"
          description="Once deleted, the link that was shared will no longer work, would you like to continue?"
          :delete="deleteEmail"
        ></dialog-delete-report>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" md="6">
        <dialog-delete-report
          :show.sync="dialog_delete_all"
          title="Are you sure to delete all emails?"
          description="Once removed, the links that were shared will no longer work, would you like to continue?"
          :delete="deleteAll"
        ></dialog-delete-report>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import DialogDeleteReport from '@/components/dialog/DialogDelete.vue'

export default {
  components: {
    DialogDeleteReport,
  },
  props: {
    report: Object,
    report_type: String,
    dialog: Boolean,
    backDialog: Function,
  },
  data() {
    return {
      loading: false,
      items: [],
      total: 0,
      headers: [
        { text: 'Email', value: 'email', sortable: true },
        { text: 'Date Sent', value: 'created_at', sortable: true },
        { text: '', value: 'actions', sortable: false, width: 50 },
      ],
      items_per_page: [5, 10, 25],
      sort_desc: true,
      options: {},
      search: {
        search: '',
      },
      dialog_delete: {
        item: {},
        show: false,
      },
      dialog_delete_all: false,
    }
  },
  watch: {
    dialog: {
      handler(val) {
        if (val == true) {
          this.getEmails()
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getEmails()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'snackbar/set',
      getSharedEmails: 'share/getSharedEmails',
      remove: 'share/remove',
      removeAll: 'share/removeAll',
    }),
    closeDialog() {
      this.$emit('update:dialog', false)
      this.$emit('close')
    },
    formatDate(value) {
      return moment(value).fromNow()
    },
    async getEmails() {
      const form_data = {
        report_id: this.report.id,
        report_type: this.report_type,
      }

      this.loading = true
      await this.getSharedEmails(new URLSearchParams(form_data).toString())
        .then(resp => {
          if (resp && resp.data) {
            this.items = resp.data
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    async showDeleteDialog(item) {
      this.dialog_delete.show = true
      this.dialog_delete.item = item
    },
    async deleteEmail() {
      this.loading = true
      this.dialog_delete.show = false

      await this.remove(this.dialog_delete.item.token)
        .then(resp => {
          this.dialog_delete.item = null
          this.getEmails()
        })
        .finally(() => {
          this.loading = false
        })
    },
    async deleteAll() {
      const form_data = {
        report_id: this.report.id,
        report_type: this.report_type,
      }

      this.loading = true
      await this.removeAll((new URLSearchParams(form_data).toString()))
        .then(resp => {
          this.dialog_delete.item = null
          this.getEmails()
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="css" scoped>
  .header{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
  @media (max-width: 450px){
    .header{
      justify-content: center;
      align-items: center;
    }
  }
</style>

